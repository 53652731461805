jQuery(function($) {
    var $header = $(".js-fix-header");

    function isOnTop() {
        var vScroll = $(window).scrollTop();
        var topEdge = 300;
        if (topEdge >= vScroll) {
            return true;
        } else {
            return false;
        }
    }

    function isMobileMedia() {
        var media = window.matchMedia("only screen and (max-width: 62em)").matches;
        return media;
    }

    function setPosition() {
        if (isOnTop()) {
            // top position;
            $header.removeClass("fixed");
        } else {
            // fixed position
            $header.addClass("fixed");
        }
    }

    // init
    if ($header.length) {
        setPosition();
        $(window).on("scroll", setPosition);
        $(window).on("resize", setPosition);
    }

}); // ready
jQuery(function($) {
    var leng = $('.pll-parent-menu-item'),
        block = leng.find('.sub-menu');

    leng.each(function() {
        var currentLang = $(this).find(".current-lang");
        currentLang.remove();
    })

    leng.on('hover', function(e) {
        block.toggleClass('open')
    })
    $(document).on('click', function(e) {
        block.removeClass('open');
    })
    $(window).on('scroll', function() {
        block.removeClass('open');
    });
    if (Modernizr.touch) {
        leng.on('click', function(e) {
            block.toggleClass('open')
        })
    }

})
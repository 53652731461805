jQuery(function($) {
    // arrow icons
    var arrowLeft = '<img src="assets/images/content/arrow-prev.png" alt="down" class="header-section_down">';
    var arrowRight = '<img src="assets/images/content/arrow-next.png" alt="down" class="header-section_down">';

    // preset options

    var howWorkCarousel = $('.js-slider');
    var howWorkOption = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 1000,
        // autoplay: true,
        // nextArrow: '<button type="button" class="slider_btn--next slider_btn animated" data-animation="fadeInRight, fadeOutRight"> ' + arrowRight + ' </button>',
        // prevArrow: '<button type="button" class="slider_btn--prev slider_btn animated" data-animation="fadeInLeft, fadeOutLeft">' + arrowLeft + '</button>',
        infinite: true,
        dots: true,
        fade: true,

    }

    // init slider

    howWorkCarousel.slick(howWorkOption);

}); // ready